<template>
  <div class="profile-page">
    <v-container fluid>
      <transition name="fade">
        <div v-if="isDataLoaded">
          <div class="text-center profile-heading">
            <span class="sw-page-title">{{
              $vuetify.lang.t("$vuetify.profileHeadline")
            }}</span>
          </div>
          <div class="profile-details">
            <v-card flat max-width="560" class="mx-auto">
              <v-row v-if="!editmode" class="no-gutters">
                <v-col cols="12">
                  <div v-if="!isLoading">
                    <v-row class="mb-6 mb-sm-12 no-gutters">
                      <v-col cols="12" class="field-name font-weight-light">{{
                        $vuetify.lang.t("$vuetify.name")
                      }}</v-col>
                      <v-col cols="12" class="field-data"
                        >{{ user.first_name }} {{ user.last_name }}</v-col
                      >
                      <v-col cols="12" class="field-name font-weight-light">{{
                        $vuetify.lang.t("$vuetify.email")
                      }}</v-col>
                      <v-col cols="12" class="field-data">{{
                        user.email
                      }}</v-col>
                      <v-col cols="12" class="field-name font-weight-light">{{
                        $vuetify.lang.t("$vuetify.phone")
                      }}</v-col>
                      <v-col cols="12" class="field-data"
                        ><template v-if="user.phone_code && user.phone">
                          +{{ user.phone_code }} {{ user.phone }}
                        </template></v-col
                      >
                      <v-col
                        v-if="groupSupportedLanguages.length"
                        cols="12"
                        class="field-name font-weight-light"
                        >{{
                          $vuetify.lang.t("$vuetify.preferredLanguages")
                        }}</v-col
                      >
                      <v-col
                        v-if="groupSupportedLanguages.length"
                        cols="12"
                        class="field-data"
                      >
                        <div v-if="!user.lang_pref || !user.lang_pref.length">
                          {{
                            $vuetify.lang.t("$vuetify.noLanguagesSelectedText")
                          }}
                        </div>
                        <template v-for="(lang, i) in user.lang_pref">
                          <span
                            :key="`seperator-${i}`"
                            v-if="i > 0"
                            class="mr-1"
                            >,</span
                          >
                          <span :key="`lang-${i}`">{{
                            $vuetify.lang.t(`$vuetify.iso2.${lang}`)
                          }}</span>
                        </template>
                      </v-col>
                      <v-col cols="12" class="text-center button-area">
                        <v-btn
                          depressed
                          class="
                            sw-mx-2
                            text-none
                            action-button
                          "
                          :loading="isLoading"
                          @click="$router.push({ name: 'FrontPage' })"
                        >
                          <span class="sw-px-2">
                            {{ $vuetify.lang.t("$vuetify.close") }}
                          </span>
                        </v-btn>
                        <v-btn
                          depressed
                          :style="{ backgroundColor: gmSecondaryColor }"
                          class="text-none white--text sw-mx-2 action-button"
                          @click="editmode = true"
                        >
                          <span class="px-8">{{
                            $vuetify.lang.t("$vuetify.edit")
                          }}</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row class="no-gutters">
                      <v-col cols="12" class="text-center">
                        <a @click.stop="logout">
                          <v-icon>mdi-exit-to-app</v-icon>
                          {{ $vuetify.lang.t("$vuetify.logOut") }}
                        </a>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <ValidationObserver ref="observer">
                <div v-if="editmode" class="edit-profile">
                  <v-row class="no-gutters edit-mode">
                    <v-col cols="12" class="field-name font-weight-light">{{
                      $vuetify.lang.t("$vuetify.name")
                    }}</v-col>
                    <v-col cols="12">
                      <ValidationProvider
                        vid="first_name"
                        name="first name"
                        rules="required"
                        mode="passive"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="user.first_name"
                          :error-messages="errors[0]"
                          :placeholder="$vuetify.lang.t('$vuetify.name')"
                          flat
                          class="field-data"
                          background-color="#f2f2f2"
                          solo
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" class="field-name font-weight-light">{{
                      $vuetify.lang.t("$vuetify.surname")
                    }}</v-col>
                    <v-col cols="12">
                      <ValidationProvider
                        vid="last_name"
                        name="last name"
                        rules="required"
                        mode="passive"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="user.last_name"
                          :error-messages="errors[0]"
                          :placeholder="$vuetify.lang.t('$vuetify.surname')"
                          flat
                          class="field-data"
                          background-color="#f2f2f2"
                          solo
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" class="field-name font-weight-light">{{
                      $vuetify.lang.t("$vuetify.email")
                    }}</v-col>
                    <v-col cols="12">
                      <ValidationProvider
                        vid="email"
                        name="email"
                        rules="required|email"
                        mode="passive"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="user.email"
                          :error-messages="errors[0]"
                          :placeholder="$vuetify.lang.t('$vuetify.email')"
                          flat
                          class="field-data"
                          background-color="#f2f2f2"
                          solo
                          disabled
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                      <v-row class="no-gutters special-editprofile-pt">
                        <v-col cols="12" sm="6" class="pr-sm-1">
                          <ValidationProvider
                            vid="phone_code"
                            name="phone code"
                            :rules="phoneCodeRules"
                            mode="passive"
                            v-slot="{ errors }"
                          >
                            <CountryCodeSelector
                              v-model="user.phone_code"
                              :errors="errors"
                              class="custom-select registration-field-rounded"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6" class="pl-sm-1">
                          <ValidationProvider
                            :rules="phoneNumberRules"
                            mode="passive"
                            name="phone"
                            :data-vv-as="$vuetify.lang.t('$vuetify.phone')"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="user.phone"
                              :error-messages="errors[0]"
                              :placeholder="$vuetify.lang.t('$vuetify.phone')"
                              flat
                              class="field-data"
                              background-color="#f2f2f2"
                              solo
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-if="groupSupportedLanguages.length"
                      cols="12"
                      class="field-name font-weight-light"
                      >{{
                        $vuetify.lang.t("$vuetify.preferredLanguages")
                      }}</v-col
                    >
                    <v-col v-if="groupSupportedLanguages.length" cols="12">
                      <v-select
                        v-model="user.lang_pref"
                        item-text="text"
                        item-value="value"
                        multiple
                        :items="groupSupportedLanguages"
                        :menu-props="{ closeOnContentClick: true }"
                        :placeholder="
                          $vuetify.lang.t('$vuetify.preferredLanguages')
                        "
                        flat
                        attach
                        class="field-data custom-select"
                        background-color="#f2f2f2"
                        solo
                      >
                        <template v-slot:selection="{ item, index }">
                          <div
                            :style="{ backgroundColor: gmPrimaryColor }"
                            class="tag-selection mr-2"
                          >
                            <span>{{
                              $vuetify.lang.t(`$vuetify.iso2.${item}`)
                            }}</span>
                          </div>
                          <span v-if="showSelectMore(index)">
                            {{ $vuetify.lang.t("$vuetify.selectMore") }}
                          </span>
                        </template>
                        <template slot="item" slot-scope="data">
                          <span>{{
                            $vuetify.lang.t(`$vuetify.iso2.${data.item}`)
                          }}</span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <div class="text-center button-area">
                    <v-btn
                      depressed
                      class="
                        sw-mx-2
                        text-none
                        action-button
                      "
                      :loading="isLoading"
                      @click="editmode = false"
                    >
                      <span class="sw-px-2">
                        {{ $vuetify.lang.t("$vuetify.cancel") }}
                      </span>
                    </v-btn>
                    <v-btn
                      depressed
                      :style="{ backgroundColor: gmSecondaryColor }"
                      class="action-button text-none white--text"
                      @click="authUpdate"
                      :loading="isLoading"
                    >
                      <span class="px-8">{{
                        $vuetify.lang.t("$vuetify.save")
                      }}</span>
                    </v-btn>
                  </div>
                </div>
              </ValidationObserver>
            </v-card>
          </div>
        </div>
      </transition>
    </v-container>
  </div>
</template>

<script>
import CountryCodeSelector from "@/components/CustomFields/CountryCodeSelector.vue";
import EventBus from "@/utils/eventBus";

export default {
  data: () => ({
    isDataLoaded: false,
    isLoading: false,
    dialCode: null,
    user: {
      lang_pref: [],
    },
    editmode: false,
  }),
  computed: {
    phoneNumberRules() {
      return process?.env?.VUE_APP_PHONE_NUMBER_RULES || "numeric|min:8|max:12";
    },
    userToken() {
      return this.$store?.getters?.userToken;
    },
    groupAttributes() {
      return this.$store?.getters?.groupAttributes?.attributes;
    },
    groupSupportedLanguages() {
      return this.groupAttributes?.supported_languages.split(",") || [];
    },
    phoneCodeRules() {
      const required = this.user.phone ? "required|" : "";
      return `${required}numeric|max:3`;
    },
  },
  components: {
    CountryCodeSelector,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.userToken) {
        next({ name: "FrontPage" });
      }
    });
  },
  watch: {
    editmode: {
      handler(val) {
        if (val) this.authProfile();
      },
    },
  },
  mounted() {
    if (this.userToken) this.authProfile();
  },
  methods: {
    showSelectMore(index) {
      if (!this.groupSupportedLanguages.length) return;

      if (this.groupSupportedLanguages.length > this.user.lang_pref.length) {
        return this.user.lang_pref.length === index + 1 ? true : false;
      }
    },
    async authProfile() {
      try {
        this.isLoading = true;

        const response = await this.$http.get(`/auth/me`);

        this.isDataLoaded = true;
        this.isLoading = false;

        if (!response?.data?.data?.id) return;

        let user = response.data.data;

        user.lang_pref = user.lang_pref.filter((el) => el);

        this.user = user;
      } catch (error) {
        if (error) {
          this.isDataLoaded = true;
          this.isLoading = false;
        }
      }
    },
    async authUpdate() {
      try {
        const valid = await this.$refs.observer.validate();

        if (!valid) return;

        this.isLoading = true;

        const specs = {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          phone_code: this.user.phone ? this.user.phone_code : "",
          phone: this.user.phone,
          lang_pref: this.user.lang_pref?.filter((el) => el),
        };

        const response = await this.$http.post(`/auth/update`, specs);

        this.isLoading = false;

        if (!response?.data?.data?.id) return;

        let user = response.data.data;

        user.lang_pref = user.lang_pref.filter((el) => el);

        this.user = user;

        this.$store.commit(
          "SET_USER_LANG_PREF",
          response.data.data.lang_pref || [],
        );

        this.editmode = false;
      } catch (error) {
        this.isLoading = false;

        const errorMessage = error?.response?.data?.error?.message;

        if (errorMessage === "The phone has already been taken.") {
          this.$refs.observer.setErrors({
            phone: [this.$vuetify.lang.t("$vuetify.phoneAlreadyTaken")],
          });
          return;
        }

        EventBus.$emit("showNotification", {
          type: "error",
          message: errorMessage,
        });
      }
    },
    async logout() {
      try {
        this.isLoading = true;

        const response = await this.$http.get(`/auth/logout`);

        this.isLoading = false;

        if (response?.status !== 200) return;

        this.$store.dispatch("clearStore");

        this.$router.push({ name: "FrontPage" }).catch((error) => {
          if (error?.name === "NavigationDuplicated") return;
        });
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.profile-page {
  padding-top: 243px;

  .profile-heading {
    margin-bottom: 10.3px;
  }

  .field-name {
    font-size: 12px;
    padding-left: 14px;
  }

  .field-data {
    background-color: #f2f2f2;
    border-radius: 20px;
    font-size: 14px;
    height: 48px;
    padding: 14px 16px;
    margin-bottom: 20px;
  }

  .tag-selection {
    padding: 5px 15px !important;
    color: #fff !important;
    text-align: center;
    display: inline-block;
    border-radius: 20px;
  }

  .edit-profile {
    .field-data {
      padding: 0;

      .v-input__slot {
        padding: 0 16px !important;
      }
    }

    .special-editprofile-pt {
      padding-top: 20px;
    }

    .custom-select .v-input__slot {
      border-radius: 20px !important;
    }
  }

  .button-area {
    padding-top: 70px;

    .action-button {
      border-radius: 20px;
      height: 52px;
      font-size: 18px;
      letter-spacing: unset;
    }
  }
}

@media (max-width: 900px) {
  .profile-page {
    padding-top: 130px;

    .container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
</style>
